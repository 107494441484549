import { createElement, useRef } from "react";

import { importAll } from "./main";


// MISC

const colorSchemes = [
    {
        text: ["8338ec", "134074", "e36414", "ffffff"],
        background: ["ffd6ff", "c8b6ff", "bbd0ff", "ffffff"]
    },
    {
        text: ["A3FFD6", "7BC9FF", "8576FF", "1C1678"],
        background: ["F27BBD", "C65BCF", "874CCC", "10439F"]
    },
    {
        text: ["3A8647", "375C3D", "71E39D", "358EA0"],
        background: ["E9B3F5", "9D93DB", "DBA2C2", "84D8DB"]
    }
]
const currentScheme = colorSchemes[2];

export const colors = {
    text: {
        primary: `#${currentScheme.text[0]}`,
        secondary: `#${currentScheme.text[1]}`,
        tertiary: `#${currentScheme.text[2]}`,
        quaternary: `#${currentScheme.text[3]}`
    },
    background: {
        primary: `#${currentScheme.background[0]}`,
        secondary: `#${currentScheme.background[1]}`,
        tertiary: `#${currentScheme.background[2]}`,
        quaternary: `#${currentScheme.background[3]}`
    }
};


//Images
export const images = importAll(require.context("../assets/", true));
export const drawings = importAll(require.context("../assets/images/drawings/", true));
export const icons = importAll(require.context("../assets/images/icons", true));


export function MediaElement(props)
{
    const element = () => {

        if(props.type == "img")
        {
            return <img ref={props.mediaRef} src={images[props.folder+props.source]} style={props.styling} className={props.class}></img>;
        }

        else if(props.type == "video") 
        {

            return (
                <video ref={props.mediaRef} {...props.options} controls style={props.styling} className={props.class}>
                    <source src={images[props.folder+props.source]} type={`video/${props.source.split(".")[props.source.split(".").length-1]}`}></source>
                </video>
            )
        }
        
        else if(props.type == "embed") {
            return (
                <iframe width={props.width} height={props.height} src={props.source} style={props.styling} className={props.class}></iframe>
            )
        }

        else if(props.type == "vimeo") {
            const source = `https://player.vimeo.com/video/${props.source}?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`;
            return (
                <>
                    <div>
                        <iframe src={source} width={props.width} height={props.height} className={props.class} style={props.styling} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title={props.title}></iframe>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </>
            )
        }

        else return <></>;
    }
    
    return (
        <>
            {props.caption == "" || props.caption == null ? <></> : <p>{props.caption}</p>}
            {element()}
        </>
    )
}

const weevilExtraContent = () => {
    return (
        <>
            <div style={{display: "flex"}}>
                <blockquote className="reddit-embed-bq" style={{height: 500+"px"}} data-embed-height="506"><a href="https://www.reddit.com/r/weeviltime/comments/15bf3e9/i_got_bored_this_afternoon/">I got bored this afternoon</a><br></br> by <a href="https://www.reddit.com/user/fightlight55/">u/fightlight55</a> in <a href="https://www.reddit.com/r/weeviltime/">weeviltime</a></blockquote><script async="" src="https://embed.reddit.com/widgets.js"></script>
                <blockquote className="reddit-embed-bq" style={{height: 500+"px"}} data-embed-height="740"><a href="https://www.reddit.com/r/weeviltime/comments/16hvna0/weevil_time_2_electric_snootaloo/">Weevil Time 2: Electric Snootaloo</a><br></br> by <a href="https://www.reddit.com/user/fightlight55/">u/fightlight55</a> in <a href="https://www.reddit.com/r/weeviltime/">weeviltime</a></blockquote><script async="" src="https://embed.reddit.com/widgets.js"></script>
            </div>
        </>
    )
}

// PROJECTS
function project(props)
{
    const newProject = {
        project: props.project,
        title: props.title,
        tagline: props.tagline,
        description: props.description,
        link: props.link,
        company: props.company,
        genre: props.genre,
        engine: props.engine,
        tags: props.tags,
        features: props.features,
        mainMedia: props.mainMedia,
        gameplay: props.gameplay, 
        banner: props.banner,
        background: props.background,
        colors: props.colors,
        gallery: props.gallery,
        roles: props.roles,
        contribution: props.contribution,
        process: props.process,
        year: props.year,
        credits: props.credits,
        notes: props.notes,
        extraContent: props.extraContent
    }

    return newProject;
}

function sideProject(props)
{
    const newProject = {
        title: props.title,
        gameplay: props.gameplay,
        company: props.company,
        genre: props.genre,
        year: props.year,
        tags: props.tags,
        roles: props.roles,
        engine: props.engine,
        notes: props.notes,
        extraContent: props.extraContent
    }

    return newProject;
}

export const projects = [
    //Weevil Time
    project({
        project: "weeviltime", 
        title: "Weevil Time!",
        tagline: "Boots, snoots, n' kjoots!",
        description: "Play as an Acorn Weevil, fighting to protect Central Park from the horrendous and invasive Spotted Lanternflies!",
        link: "https://jcrose.itch.io/weevil-time",
        company: "Independent Project",
        genre: "Shooter",
        engine: "Unity",
        tags: ["2D", "Bullet Hell", "Environmental"],
        features: ["Collectible power ups & utility", "Scoring system", "Charged ability/status"],
        mainMedia: {type: "img", source: "WeevilTime/weevil.png"},
        gameplay: {type: "vimeo", source: "1002148004", title: "Weevil Time Gameplay"},
        banner: images["projects/WeevilTime/weevil_bg.png"],
        background: "",
        colors: {
            primary: '#29bf12',
            secondary: '#abff4f',
            tertiary: '#ff9914',
            quaternary: '#136f63'
        },
        gallery: [
            {type: "img", source: "WeevilTime/weevil_start.png", caption: "Start screen"},
            {type: "img", source: "WeevilTime/weevil_gp.png", caption: "Gameplay"},
            {type: "img", source: "WeevilTime/weevil_ld.png", caption: "Level design"}
        ],
        roles: ["Level Designer", "Programmer", "Artist", "Sound Designer"],
        contribution: "I originally quickly designed a sidescrolling infinite runner with placeholder assets found on Google, but later scrapped the project to give more player input and enhance gameplay. Using Unity, Piskel, and BeepBox, I developed this prototype solo.",
        process: "The idea stemmed from my love of the WeevilTime subreddit and knowledge of the recent invasion of Spotted Lanternfly in the US and other parts of the world. After a short time brainstorming, I got to work on a silly little infinite runner in Unity and shared the outline to the aforementioned subreddit. I did not continue with the project until a few months later, when I decided to remake the game and incorporated some features that were suggested. I made the artwork in Piskel, some sounds on BeepBox, and used my Unity and C# skills to put everything together!",
        year: 2023,
        credits: [],
        notes: ["Dedicated to all the weevil fans out there ... and obviously all the weevils", 
                "Information on Spotted Lanternflies can be found on the US Dept. of Agriculture website - if you are in the United States, South Korea or Japan, be on the lookout for these invasive creatures and report all sightings!"],
        extraContent: [{title: "Reddit Posts", content: weevilExtraContent()}]
    }),
    //Boat Game
    project({
        project: "boatgame", 
        title: "Boat Game",
        tagline: "Sliding puzzle game, with boats!",
        description: "Drag the obstructive boats on their respective axes to make way for the objective boat to complete the puzzles!",
        link: "",
        company: "Upwork",
        genre: "Puzzle",
        engine: "Unity",
        tags: ["Isometric", "Relaxing", "Mobile", "Prototype"],
        features: ["Level selection", "Movement restrictions", "Ascending difficulty and complexity in puzzles"],
        mainMedia: {type: "img", source: "BoatGame/boat.png"},
        gameplay: {type: "vimeo", source: "1004947948", title: "Boat Game Gameplay"},
        banner: images["projects/BoatGame/Boatbanner.png"],
        background: "",
        colors: {
            primary: '#023047',
            secondary: '#8ecae6',
            tertiary: '#ffb703',
            quaternary: '#2176ff'
        },
        gallery: [
            {type: "img", source: "BoatGame/boat_start.png", caption: "Start screen"},
            {type: "img", source: "BoatGame/boat_complete.png", caption: "Level complete"},
            {type: "img", source: "BoatGame/boat_pause.png", caption: "Pause menu"},
            {type: "img", source: "BoatGame/boat_level01.png", caption: "Level 1"},
            {type: "img", source: "BoatGame/boat_level02.png", caption: "Level 2"},
            {type: "img", source: "BoatGame/boat_level03.png", caption: "Level 3"},
            {type: "img", source: "BoatGame/boat_level04.png", caption: "Level 4"}
        ],
        roles: ["Level Designer", "Programmer"],
        contribution: "I was hired on Upwork to make a prototype for this independent project; using Unity Store assets, I designed and programmed the game for mobile using Unity and Xcode",
        process: "The rough idea was presented to me prior to my start, and I began doing some research on the potential mechanics I had in mind. After some trial and error, and famiiarizing myself more with raycasting logic, I made a functional sliding mechanic including collision and boundaries. The project files were then passed off upon me finishing my portion.",
        year: 2020,
        credits: [
            {
                name: "Unity Store Assets",
                role: "Assets - Water, Boats, Textures"
            }
        ],
        notes: ["The list of specific assets and their respective artists are inaccessible to me - my apologies!"],
        extraContent: []
    }),
    //Pugs For Scale
    project({
        project: "pugsforscale", 
        title: "Pugs for Scale",
        tagline: "Physics based 2D sidescroller ... with pugs for scale",
        description: "Using your mouse, slingshot the polygon to try to land on the goal area - missing the mark results in various penalties!",
        link: "https://jcrose.itch.io/pugs-for-scale",
        company: "Independent Project",
        genre: "Strategy",
        engine: "Unity",
        tags: ["2D", "Physics", "Prototype"],
        features: ["Exponential scoring system", "Programmatic generation of shapes", "Game map viewport"],
        mainMedia: {type: "img", source: "PugsForScale/puggie.png"},
        gameplay: {type: "vimeo", source: "1004873237", title: "Pugs for Scale Gameplay"},
        banner: images["projects/PugsForScale/pugbanner.png"],
        background: images["projects/PugsForScale/pugbg.jpg"],
        colors: {
            primary: '#fefae0',
            secondary: '#d4a373',
            tertiary: '#ccd5ae',
            quaternary: '#f5ee9e'
        },
        gallery: [
            {type: "img", source: "PugsForScale/PFSMain.png", caption: "Main menu"},
            {type: "img", source: "PugsForScale/PFSEnd.png", caption: "End game"},
            {type: "img", source: "PugsForScale/PFSGoal.png", caption: "Goal!"},
            {type: "img", source: "PugsForScale/PFSOver.png", caption: "Overshot"},
            {type: "img", source: "PugsForScale/PFSUnder.png", caption: "Undershot"}
        ],
        roles: ["Level Designer", "Programmer"],
        contribution: "Using some research and my love for game physics, I messed around with some concepts programmatically and in editor, and implemented some design and gameplay features to give the game more replayability",
        process: "Wanted to make a physics-based game, I revisited and expanded on a mechanic I toyed with a few years back: a slingshot. I thought having a variation of shapes would be a nice touch, giving the player more to learn, as well as adding torque for a bit more unpredictability and challenge. I also added pugs for scaling reference because pugs are amazing!",
        year: 2024,
        credits: [
            {
                name: "Joanna (via Stickerapp.com)",
                role: "Artwork - Pug image"
            }
        ],
        notes: [],
        extraContent: []
    }),
    //Astral
    project({
        project: "astral", 
        title: "Astral",
        tagline: "Space-themed 2D Roguelike",
        description: "Traverse the spaceship to escape with the help of your jetpack and slingshot!",
        link: "https://jcrose.itch.io/astral",
        company: "DePaul University",
        genre: "Roguelike",
        engine: "Unity",
        tags: ["2D", "Adventure", "Physics", "Puzzle"],
        features: ["Level progression", "Enemies with pathing", "Shop and currency system", "Object interaction"],
        mainMedia: {type: "img", source: "Astral/astral.png"},
        gameplay: {type: "vimeo", source: "1004876256", title: "Astral Gameplay"},
        banner: images["projects/Astral/astralbanner.jpg"],
        background: "",
        colors: {
            primary: '#22223b',
            secondary: '#dee2e6',
            tertiary: '#9a8c98',
            quaternary: '#829cbc'
        },
        gallery: [
            {type: "img", source: "Astral/astralmenu.PNG", caption: "Menu"},
            {type: "img", source: "Astral/astralend.PNG", caption: "End game"},
            {type: "img", source: "Astral/astrallevel1.PNG", caption: "Level 1"},
            {type: "img", source: "Astral/astrallevel2.PNG", caption: "Level 2"},
            {type: "img", source: "Astral/astrallevel3.PNG", caption: "Level 3"}
        ],
        roles: ["Level Designer", "Programmer", "Artist", "Sound Designer"],
        contribution: "Tasked with coming up with a unique, multifunctional mechanic and subsequently independently developing a game from said feature, I fulfilled all roles of development for this roguelike.",
        process: "Developed in Unity, this project stemmed from my approach to develop an interesting physics-based mechanic for a platformer. I liked the slingshot concept and ran with it, making it multifunctional as both a combat feature and for solving puzzles. I designed the levels with tilemaps, using drawings I made in Piskel, pairing the design with my gameplay programming.",
        year: 2019,
        credits: [],
        notes: ["Solo Game Development class project at DePaul"],
        extraContent: []
    }),
    //Barnyard Squabble
    project({
        project: "barnyardsquabble", 
        title: "Barnyard Squabble",
        tagline: "A collection of wacky minigames with animals",
        description: "Challenge friends in these five multiplayer minigames!",
        link: "",
        company: "DePaul University",
        genre: "Party",
        engine: "Unity",
        tags: ["2D", "Multiplayer", "Controllers"],
        features: ["Controller technical implementation", "Main menu game and instruction selection", "Scoring system and player state management"],
        mainMedia: {type: "img", source: "Barnyard/barnyard.png"},
        gameplay: {type: "vimeo", source: "1007778145", title: "Barnyard Squabble"},
        banner: images["projects/Barnyard/barnyardbanner.png"],
        background: "",
        colors: {
            primary: '#2ec4b6',
            secondary: '#e71d36',
            tertiary: '#ff9f1c',
            quaternary: '#f37748'
        },
        gallery: [
            {type: "img", source: "Barnyard/bscatrancher.PNG", caption: "Cat Rancher"},
            {type: "img", source: "Barnyard/bskeepaway.PNG", caption: "Keep Away"},
            {type: "img", source: "Barnyard/bskoth.PNG", caption: "King of the Hill"},
            {type: "img", source: "Barnyard/bsmountain.PNG", caption: "Mountain Top"},
            {type: "img", source: "Barnyard/bstag.PNG", caption: "Tag"}
        ],
        roles: ["Level Designer", "Programmer"],
        contribution: "For this project - the capstone project for my degree - I functioned as the lead programmer as well as doing some level design work. I handled the overall game programming (scene/state management, controller functionality, etc.) and with individual minigames, namely Cat Rancher.",
        process: "We had the idea to make multiplayer games, exclusively with controller compatibility. Developed in Unity and using a connectable hub for controller inputs, we developed this collection of minigames over the course of two trimesters",
        year: 2019,
        credits: [
            {
                name: "Michael Struck",
                role: "Level Designer"
            },
            {
                name: "Jared Walsh",
                role: "Programmer"
            },
            {
                name: "Brianne Almalvez",
                role: "Artist"
            },
            {
                name: "Peter McDonald",
                role: "Progammer"
            }
        ],
        notes: ["Cat Rancher - Collection game where players need to herd cats to their pens, while being able to steal from one another until the time runs out", 
                "Keep Away - Hold onto the object as long as possible, using it (sometimes riskily) as a projectile to stun enemies",
                "King of the Hill - Stand on the base to gain points during the game's timeframe",
                "Mountain Top - Push other players off the slippery mountain to win as the last person standing",
                "Tag - Tag, you're it! (and the shortest time as 'it' wins!)"
            ],
        extraContent: []
    }),
    //7 Ate 9
    project({
        project: "sevenatenine", 
        title: "7 Ate 9",
        tagline: "Run from Number 7! Run, run, run!",
        description: "Solve equations to avoid getting caught by the vicious number 7.",
        link: "",
        company: "DePaul University",
        genre: "Infinite Runner",
        engine: "Unity",
        tags: ["2D", "Math", "Educational"],
        features: ["Scoring", "Equation generation"],
        mainMedia: {type: "img", source: "7ate9/7ate9.png"},
        gameplay: {type: "vimeo", source: "1007776233", title: "7 Ate 9 Gameplay"},
        banner: images["projects/7ate9/7ate9banner.png"],
        background: "",
        colors: {
            primary: '#072ac8',
            secondary: '#dd1c1a',
            tertiary: '#f0c808',
            quaternary: '#407ba7'
        },
        gallery: [
            {type: "img", source: "7ate9/7ate9start.PNG", caption: "Main menu"},
            {type: "img", source: "7ate9/7ate9endgame.PNG", caption: "End game"},
            {type: "img", source: "7ate9/7ate9gameplay1.PNG", caption: "Gameplay"},
            {type: "img", source: "7ate9/7ate9rules.PNG", caption: "Rules"}
        ],
        roles: ["Level Designer", "Programmer"],
        contribution: "I was the lead programmer for this educational game, and worked on some UI design as well. This was for a Game Production class, so a large portion of development was learning the process of using development pipelines, practicing scrum, and more, primarily through use of Google documents and spreadsheets which I had curated. I truly had a blast working on this - I love math and educating!",
        process: "Since we were going to showcase this game at Mathnasium to some kids, we wanted to make something silly, fun, and replayable. With a bit of nostalgia and a classic math pun, we developed this 2D infinite runner in Unity with varying complexity of problems to solve and a competitive scoring system.",
        year: 2019,
        credits: [
            {
                name: "Nick Schorgl",
                role: "Artist"
            },
            {
                name: "Colin Kieny",
                role: "Level Designer"
            }
        ],
        notes: [],
        extraContent: []
    }),
    //Baja Big Air
    project({
        project: "bajabigair", 
        title: "Baja Big Air",
        tagline: "Adrenaline Fueled Racing",
        description: "Do you have what it takes to complete the toughest race in off road motorsports? Baja Big Air is an adrenaline fueled off road racing game. The official game of SCORE International's Baja Desert Race Series. Get behind the wheel of a Trophy Truck as you take on one of the toughest races in off road motorsports, the Baja 1000. Race across the harsh desert of Baja California, Mexico as you fend off legendary racers including Tavo Vildosola, Casey Currie, Alan Ampudia and more. Do you have what it takes to be the best?",
        link: "https://floor84studio.com/game-baja-big-air.php",
        company: "Floor 84 Studio",
        genre: "Racing",
        engine: "Unity",
        tags: ["2D", "Customization", "Multiplayer", "Mobile"],
        features: ["Gear toggle mechanic", "Leaderboards and social media connectivity", "Level progression", "AI racers"],
        mainMedia: {type: "img", source: "Baja/baja.jpg"},
        gameplay: {type: "embed", source: "https://www.youtube.com/embed/eTPQswgLt2Q?si=EE5lmbmS3_HitkIl"},
        banner: images["projects/Baja/bajabanner.jpg"],
        background: "",
        colors: {
            primary: '#01baef',
            secondary: '#ff002b',
            tertiary: '#cfdee7',
            quaternary: '#8093f1'
        },
        gallery: [
            {type: "img", source: "Baja/BBA1.jpg", caption: ""},
            {type: "img", source: "Baja/BBA2.png", caption: ""},
            {type: "img", source: "Baja/BBA3.png", caption: ""},
            {type: "img", source: "Baja/BBA4.png", caption: ""},
            {type: "img", source: "Baja/BBA5.png", caption: ""},
        ],
        roles: ["Level Designer"],
        contribution: "I worked on creating levels of varying complexity (90 or so levels, 3 difficulties) during my summer internship at F84 in 2019.",
        process: "Using assets made by F84 artists, I formed levels in Tiled and Photoshop to be put into Unity, where I tested them and recorded ghost data for gameplay.",
        year: 2019,
        credits: [
            {
                name: "Floor 84 Games",
                role: "Employer - all development"
            },
            {
                name: "Floor84Studio.com",
                role: "Images, quotes, etc."
            }
        ],
        notes: ["Primary project during my internship"],
        extraContent: []
    })
];

/* Project template
project({
    project: "", 
    title: "",
    tagline: "",
    description: "",
    link: "",
    company: "",
    genre: "",
    engine: "",
    tags: [],
    features: [],
    mainMedia: {type: "", source: ""},
    gameplay: {type: "", source: ""},
    banner: images[""],
    background: "",
    colors: {
        primary: '#',
        secondary: '#',
        tertiary: '#',
        quaternary: '#'
    },
    gallery: [
        
    ],
    roles: [],
    contribution: "",
    year: "",
    credits: [],
    notes: []
})
*/

export const otherProjects = [
    sideProject({
        title: "This is Urchint!",
        gameplay: {type: "vimeo", source: "1002817113", title: "This Is Urchint! Gameplay"},
        company: "DePaul University",
        genre: "Tower Defense",
        year: 2016,
        tags: ["2D", "Environmental"],
        roles: ["Level Designer"],
        engine: "GameMaker",
        notes: ["First game at DePaul"]
    }),
    sideProject({
        title: "Rockstar's Revenge",
        gameplay: {type: "vimeo", source: "1002822772", title: "Rockstar's Revenge Gameplay"},
        company: "DePaul University",
        genre: "Rhythm",
        year: 2018,
        tags: ["2D"],
        roles: ["Programmer", "Level Designer"],
        engine: "Unity",
        notes: []
    }),
    // sideProject({
    //     title: "Haunted House",
    //     gameplay: "",
    //     company: "DePaul University",
    //     genre: "Horror",
    //     year: 2016,
    //     tags: ["2D"],
    //     roles: ["Level Designer"],
    //     engine: "GameMaker",
    //     notes: []
    // }),
    sideProject({
        title: "Android Assault",
        gameplay: {type: "vimeo", source: "1002828412", title: "Tugboat Simulator Gameplay"},
        company: "Emagination Tech Camps",
        genre: "Shooter",
        year: 2014,
        tags: ["Space", "First Person", "3D"],
        roles: ["Programmer", "Level Designer"],
        engine: "Unreal Engine 3",
        notes: ["First game"]
    }),
    sideProject({
        title: "Tugboat Sim",
        gameplay: {type: "vimeo", source: "1002826453", title: "Tugboat Simulator Gameplay"},
        company: "DePaul Tech Camp",
        genre: "Shooter",
        year: 2014,
        tags: ["3D"],
        roles: ["Programmer"],
        engine: "Unity",
        notes: []
    }),
    sideProject({
        title: "Sub Spy",
        gameplay: {type: "img", source: "SubSpy/level1.jpg", folder: "projects/Other/"},
        company: "Emagination Tech Camps",
        genre: "Stealth",
        year: 2015,
        tags: ["First Person", "3D"],
        roles: ["Programmer"],
        engine: "Unreal Engine 3",
        notes: []
    }),
    sideProject({
        title: "Golf Game",
        gameplay: {type: "vimeo", source: "1002819829", title: "Golf Game Gameplay"},
        company: "DePaul University",
        genre: "Puzzle",
        year: 2018,
        tags: ["2D"],
        roles: ["Programmer", "Level Designer"],
        engine: "Unity",
        notes: []
    }),
    sideProject({
        title: "Puzzle Game",
        gameplay: {type: "vimeo", source: "1002824730", title: "Puzzle Game Gameplay"},
        company: "DePaul University",
        genre: "Puzzle",
        year: 2018,
        tags: ["3D", "Top Down", "Puzzle"],
        roles: ["Programmer", "Level Designer"],
        engine: "Unity",
        notes: ["Customized levels for puzzle project using provided assets"]
    }),
    sideProject({
        title: "Platformer",
        gameplay: {type: "vimeo", source: "1002803223", title: "Platformer Gameplay"},
        company: "DePaul University",
        genre: "Roguelike",
        year: 2017,
        tags: ["2D", "Platformer"],
        roles: ["Programmer", "Level Designer", "Artist"],
        engine: "GameMaker",
        notes: []
    }),
    sideProject({
        title: "Meatball Toss",
        gameplay: {type: "vimeo", source: "1002821788", title: "Meatball Toss Gameplay"},
        company: "DePaul University",
        genre: "Minigame",
        year: 2018,
        tags: ["2D", "Physics"],
        roles: ["Programmer", "Level Designer", "Artist"],
        engine: "Unity",
        notes: ["Short class project for core mechanic in Astral"]
    })
];


// ABOUT

function item(text, level = 0)
{
    return {text: text, level: level};
}

export const aboutLists = [
    //Concepts
    {title: "Concepts", key: 0, num: 0, list: [
        item("Front-end Programming", 3),
        item("Level Design", 3),
        item("UI/UX", 3),
        item("Prototyping", 3),
        item("Object Oriented Programming", 3),
        item("Web Hosting", 2),
        item("SEO", 1),
        item("Animation", 2),
        item("Graphic Design", 2),
        item("Sound Design", 2)
    ]},
    //Industry 
    {title: "Industry", key: 1, num: 1, list: [
        item("Quality Assurance", 3),
        item("Project Management", 3),
        item("Data Management", 2),
        item("Scrum", 3),
        item("Version/Source Control", 3),
        item("Research & Documentation", 3)
    ]},
    //Software
    {title: "Software", key: 2, num: 2, list: [
        item("Unity", 3),
        item("Microsoft Excel / Google Sheets", 3),
        item("Unreal Engine", 2),
        item("Adobe Photoshop", 2),
        item("Autodesk Maya", 1),
        item("Audacity", 1),
        item("GameMaker", 2),
        item("Tiled", 1)
    ]},
    //Tools
    {title: "Tools", key: 3, num: 3, list: [
        item("React", 3),
        item("Git", 2),
        item("Angular", 1),
        item("CLI", 1),
        item("Node.js", 2),
        item("Piskel", 2)
    ]},
    //Languages
    {title: "Languages", key: 4, num: 4, list: [
        item("C#", 3),
        item("JavaScript", 3),
        item("HTML/CSS", 3),
        item("SQL", 2),
        item("TypeScript", 2),  
        item("C++", 1),  
        item("PHP", 2),  
        item("Lua", 1),
        item("Java", 2)
    ]},
    //Attributes
    {title: "Attributes", key: 5, num: 5, list: [
        item("Problem solving"),
        item("Critical thinking"),
        item("Fast and eager learner"),
        item("High value of culture"),
        item("Communication and articulation skills"),
        item("Leadership")
    ]},
    //Facts
    {title: "Facts", key: 6, num: 6, list: [
        item("Pug, fish, shrimp, and snail dad"),
        item("Guitarist (and a bit of piano)"),
        item("Lover of nature and science"),
        item("Former President of DePaul University's chapter of Sigma Phi Epsilon"),
        item("Mensa member")
    ]}
];
