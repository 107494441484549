import { useState, useRef, useEffect, useContext } from 'react';
import emailjs from '@emailjs/browser';

import '../styles/about.css';
import { SectionHead } from './main';
import { images, aboutLists, colors } from './data';
import { PopupContext } from '..';
import { DisplayBox } from './other';



export function About(props)
{
    const popup = useContext(PopupContext);

    const aboutListContent = () => {
        let content = [];
        for(let i = 0; i < aboutLists.length; i++)
        {
            const newContent = {content: <AboutListContent list={aboutLists[i].list}/>, ...aboutLists[i]};
            content = [...content, newContent];
        }
        return content;
    }

    const listStyling = {
        "--about-list-bg": colors.background.primary,
        "--about-select": colors.background.tertiary,
        color: colors.text.quaternary
    }

    const contactButtonStyling = {
        "--contact-button-text": colors.text.secondary,
        "--contact-button-bg": colors.background.tertiary,
        "--contact-button-hover": colors.background.quaternary
    }

    return (
        <div className="about-container">
            <SectionHead title={props.title}/>
            <div className="about-content" style={{color: colors.text.secondary}}>
                <div className="about-main" style={{backgroundColor: colors.background.secondary}}>
                    <img src={images["other/about.jpg"]} alt="Me!"></img>
                    <Info />
                </div>
                <div className="about-side" style={listStyling}>
                    <h4 style={{color: colors.text.secondary}}>Select lists below</h4>
                    <DisplayBox content={aboutListContent()} boxClass="about-list-element" contentClass="about-list"/>
                    <button id="contactButton" onClick={() => popup(<ContactForm />)} style={contactButtonStyling}>Contact Me!</button>
                </div>
            </div>
        </div>
    );
}

//Sections

function Info()
{

    return (
        <div className="about-info">
            <p>My name is Jonathan Rose, thank you for visiting my website!</p>
            <p>
                I graduated from DePaul University with a Bachelor's of Science degree in Game Design.
                Since then, I have worked as a QA tester and now software developer on web applications, 
                honing my skills and knowledge in front and back end development, data management, and more.
            </p>
            <p>
                Every aspect of application development fascinates me, but front-end game design holds a special place in my heart.
                I strive to learn all I can, and paired with my desire to make the world a better place in some shape or form, 
                I have quite the ambition when it comes to development, always keeping purpose, efficiency, psychology, and enjoyability in mind.
            </p>
        </div>
    );  
}


function AboutListContent(props) {

    const listItem = (item) => {

        const stars = (level) => {
            let starList = [];
            for(let i = 0; i < 3; i++)
            {
                starList = [...starList, (i+1<=level ? <p key={i}>&#9733;</p> : <p key={i}>&#9734;</p>)];
            }
            return starList;
        }

        const noStarStyle = {
            marginTop: '5%',
            fontSize: '1rem'
        };

        return(
            <div className="list-item-display" key={item.text}>
                <h6 style={item.level == 0 ? noStarStyle : {}}>{item.text}</h6>
                {item.level == 0 ? <></> : 
                    <div>
                        {stars(item.level)}
                    </div>}
            </div>
    )};

    const orderedList = () => {
        let items = [];

        for(let i = 3; i >= 0; i--)
        {
            for(let j = 0; j < props.list.length; j++)
            {
                if(props.list[j].level == i)
                {
                    items = [...items, props.list[j]];
                }
            }
        }
        
        return items;
    }

    return(
        <div key={props.list.title} className="about-list-items">
            {orderedList().map((item) => {
                {return listItem(item)}
            })}
        </div>
)};


// CONTACT
function ContactForm()
{
    const [submitting, setSubmitting] = useState(false);
    const [stateMessage, setStateMessage] = useState(null);

    const sendEmail = (e) => {
        e.persist();
        e.preventDefault();
        setSubmitting(true);
        emailjs
            .sendForm(
                'service_pffucou',
                'template_c6tm0ca',
                e.target,
                {publicKey: 'LLJYJkXNXE3IQ5ljB'}
            )
            .then(
                (result) => {
                    setStateMessage('Message sent!');
                    setSubmitting(false);
                    setTimeout(() => {
                        setStateMessage(null);
                    }, 5000);
                },
                (error) => {
                    setStateMessage('Something went wrong');
                    setSubmitting(false);
                    setTimeout(() => {
                        setStateMessage(null);
                    }, 5000);
                });
            e.target.reset();
    }

    const contactStyling = {
        '--contact-bg': `url(${images['other/envelope.png']})`,
        '--contact-label-text': colors.text.primary,
        '--contact-label-bg': colors.background.secondary+90,
        '--contact-input-bg': colors.background.quaternary,
        '--contact-submit-text': colors.text.secondary,
        '--contact-submit-bg': colors.background.primary,
        '--contact-submit-hover': colors.background.quaternary
    };


    return (
        <div className="content-container contact-content">
            <form className="contact-form" onSubmit={sendEmail} style={contactStyling}>
                <h1 style={{color: colors.text.primary}}>Contact Me!</h1>
                <div className="contact-form-fields">
                    <div>
                        <label htmlFor="name">Name: </label>
                        <input type="text" id="name" name="name"></input>
                    </div>
                    <div>
                        <label htmlFor="email">Email: </label>
                        <input type="email" id="email" name="email" required></input>
                    </div>
                    <div>
                        <label htmlFor="subject">Subject: </label>
                        <input type="text" id="subject" name="subject"></input>
                    </div>
                    <div>
                        <label htmlFor="message">Message</label><br></br>
                        <textarea id="message" name="message" cols="30" rows="4" required></textarea>
                    </div>
                </div>
                <input type="submit" value="Send" disabled={submitting}></input>
            </form>
            {stateMessage && <p>{stateMessage}</p>}
        </div>
    );
}
