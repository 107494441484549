import React, {useState, useContext, useEffect, useRef} from 'react';

import { SectionHead, getUniqueValues } from './main';
import { projects, otherProjects, colors, MediaElement, images } from './data';
import { PopupContext } from '..';
import { Gallery, Carousel } from './other';
import '../styles/core.css';
import '../styles/projects.css';
import '../styles/other.css';

//DATA
const genres = [projects.map((project) => project.genre), otherProjects.map((project) => project.genre)];
const tags = [projects.map((project) => project.tags), otherProjects.map((project) => project.tags)];
const companies = [projects.map((project) => project.company), otherProjects.map((project) => project.company)];
const roles = [projects.map((project) => project.roles), otherProjects.map((project) => project.roles)];
const years = [projects.map((project) => project.year), otherProjects.map((project) => project.year)];


// ELEMENTS
export function Projects(props)
{
    const popup = useContext(PopupContext);

    const [listActive, setListActive] = useState(false);
    const [listStyle, setListStyle] = useState({});

    const listPosition = 10+'vh';
    const listOpenTime = 500+'ms';
    const listCloseTime = 300+'ms';

    const listButtonStyling = {
        "--list-button-text": colors.text.primary,
        "--list-button-bg": colors.background.tertiary,
        "--list-button-hover": colors.background.quaternary
    }

    const carouselProjectList = () => {
        let list = [];
        const sortedProjects = [...projects].sort((a,b) => {return a.year > b.year ? -1 : 1})
        for(let i = 0; i < sortedProjects.length; i++)
        {
            list = [...list, carouselDisplay(sortedProjects[i], i)];
        }
        return list;
    };

    const carouselDisplay = (project, num) => {
        return(
            <div className="project-carousel-item" key={num} onClick={() => popup(<ProjectPage num={num}/>)}>
                <div className="project-carousel-hover" style={{backgroundColor: colors.background.quaternary}}>
                    <h1>{project.title}</h1>
                    <h3>{project.genre}</h3>
                    <h4>{project.company}</h4>
                </div>
                <MediaElement {...project.mainMedia} folder="projects/"/>
            </div>
        );
    };

    useEffect(() => {
        let style = {};
        if(listActive)
        {
            style = {
                transitionDuration: listOpenTime,
                top: listPosition
            }
        }
        else style = {
            transitionDuration: listCloseTime,
            top: 100+'vh'
        }
        setListStyle(style);
    }, [listActive])

    function toggleList()
    {
        const isActive = !listActive;
        setListActive(isActive);
    }

    return (
        <div className="projects-content" style={{color: colors.text.secondary}}>
            <SectionHead title={props.title}/>
            <h3>Use the carousel or full list button to view projects!</h3>
            <Carousel class="project-carousel" items={carouselProjectList()} styling={{backgroundColor: colors.background.secondary, color: colors.text.secondary}}/>
            <button id="projectListButton" onClick={() => toggleList()} style={listButtonStyling}>Full List</button>
            <div className="project-list-window" style={listStyle}>
                <div className="project-list-close">
                    <button onClick={() => toggleList()} style={{backgroundColor: colors.background.primary}}>
                        <h2>&uarr;</h2>
                    </button>
                </div>
                <ProjectFullList />
            </div>
        </div>
    );
    
}

function ProjectPage(props) {

    const [gameplayShown, setGameplayShown] = useState(true);
    const data = projects[props.num];
    

    const mainSection = () => {

        const coreInfo = () => {
            return (
                <div className="core-info">
                    <div className="core-info-tags">
                            <h2>Tags</h2>
                            <div>
                                {data.tags.map((tag) => {
                                    return <h5 key={tag}>{tag}</h5>
                                })}
                            </div>
                    </div>
                    <div className="core-info-bottom">
                        <div className="core-info-main"> 
                            <div>
                                <h4>Genre</h4>
                                <h3>{data.genre}</h3>
                            </div>
                            <div>
                                <h4>Engine</h4>
                                <h3>{data.engine}</h3>
                            </div>
                            <div>
                                <h4>Year</h4>
                                <h3>{data.year}</h3>
                            </div>
                        </div>
                        <div className="core-info-other">
                            <h2>Features</h2>
                            <ul className="core-info-features">
                                {data.features.map((item) => {
                                    return <li key={item}>{item}</li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }

        const mainStyle = {
            "--main-gradient-first": data.colors.secondary,
            "--main-gradient-second": data.colors.tertiary
        }

        return(
            <div className="project-page-main" style={mainStyle}>
                <div className="project-page-title">
                    <MediaElement {...data.mainMedia} folder="projects/"/>
                    <h1>{data.title}</h1>
                    <h4>{data.tagline}</h4>
                    {data.link == "" ? <></> : <a href={data.link} target="_blank">Play here</a>}
                </div>
                <div className="project-page-title-thin">
                    <div className="project-page-title-other">
                        <h2>Description</h2>
                        <p>{data.description}</p>
                    </div>
                    {coreInfo()}
                </div>
            </div>
        );
    }

    const infoSection = () => {

        // SECTIONS

        const contributionInfo = () => {
            return (
                <div className="project-contribution">
                    <div className="project-roles">
                        <h2>Roles</h2>
                        <p>{data.roles.join(', ')}</p>
                    </div>
                    <p>{data.contribution}</p>
                </div>
            )
        }

        const processInfo = () => {
            return (
                <div className="project-process">
                    <p>{data.process}</p>                
                </div>
            )
        }

        const detailsInfo = () => {
            return(
                <div className="project-other-notes" style={{display: data.notes.length == 0 ? "none" : "block"}}>
                    {data.notes.map((note) => {
                        return <p key={data.notes.indexOf(note)}>{note}</p>
                    })}
                </div>
            )
        }

        const creditsInfo = () => {
            return (
                <div className="project-other-credits" style={{display: data.credits.length == 0 ? "none" : "block"}}>
                    {data.credits.map((credit) => {
                        return (
                            <div key={credit.name}>
                                <h4>{credit.name}</h4>
                                <p>{credit.role}</p>
                            </div>
                        )
                    })}
                </div>
            )
        }

        const mediaDisplay = () => {

            const galleryStyle = {
                '--gallery-background': data.colors.quaternary,
                '--gallery-list-background': data.colors.primary+30,
                display: gameplayShown ? "none" : "flex"
            }

            const gameplayStyle = {
                display: gameplayShown ? "block" : "none"
            }

            const gameplayButtonStyle = {
                backgroundColor: gameplayShown ? colors.background.tertiary : colors.background.primary
            }

            const galleryButtonStyle = {
                backgroundColor: gameplayShown ? colors.background.primary : colors.background.tertiary
            }

            return(
                <div className="project-media-display">
                    <div className="project-media-buttons">
                        <button onClick={() => setGameplayShown(true)} style={gameplayButtonStyle} disabled={gameplayShown}>Gameplay</button>
                        <button onClick={() => setGameplayShown(false)} style={galleryButtonStyle} disabled={!gameplayShown}>Gallery</button>
                    </div>
                    {data.gallery.length > 0 ? <Gallery class="project-gallery" media={data.gallery} folder="projects/" styling={galleryStyle} mediaStyling="project-gallery-media"/> : <></>} 
                    <MediaElement {...data.gameplay} width="500" height="300" styling={gameplayStyle} class="project-gameplay"/>
                </div>
            )
        };

        const extraContentSection = (content) => {
            return (
                <>
                    {content.map((data) => {
                        return section({title: data.title, content: data.content})
                    })}
                </>
            )
        }

        // SECTION MAKER
        const section = (props) => {

            const sectionStyle = {
                "--section-gradient-first": data.colors.secondary,
                "--section-gradient-second": data.colors. quaternary
            }

            return (
                <div className="project-page-section" key={props.title} style={sectionStyle}>
                    <h1>{props.title}</h1>
                    {props.content}
                </div>
            )
        }

        return (
            <div className="project-page-info">
                {mediaDisplay()}
                {section({title: "My contribution", content: contributionInfo()})}
                {section({title: "The process", content: processInfo()})}
                {data.notes.length == 0 ? <></> : section({title: "Details", content: detailsInfo()})}
                {data.credits.length == 0 ? <></> : section({title: "Credits", content: creditsInfo()})}
                {data.extraContent.length == 0 ? <></> : extraContentSection(data.extraContent)}
            </div>
        );
    }

    const bgStyle = {
        backgroundImage: `url(${data.background})`, 
        backgroundColor: data.colors.primary,
    };

    const banner = (isTop) => {
        const bannerStyle = {
            backgroundImage: `url(${data.banner})`,
            backgroundColor: data.colors.quaternary
        };

        return <div className="project-banner" style={bannerStyle}></div>
    }
    
    return(
        <div>
            {banner(true)}
            <div className="project-page" style={bgStyle}>
                {mainSection()}
                {infoSection()}
            </div>
            {banner(false)}
        </div>
    );
}

function ProjectFullList(){

    const popup = useContext(PopupContext);

    const sortedGenres = getUniqueValues(genres).sort((a, b) => {return a > b ? 1 : -1});
    const sortedTags = getUniqueValues(tags, 2).sort((a, b) => {return a > b ? 1 : -1});
    const sortedCompanies = getUniqueValues(companies).sort((a, b) => {return a > b ? 1 : -1});
    const sortedRoles = getUniqueValues(roles, 2).sort((a, b) => {return a > b ? 1 : -1});
    const sortedYears = getUniqueValues(years).sort((a, b) => {return a > b ? -1 : 1});

    const tagRefs = useRef([]);
    const rolesRefs = useRef([]);

    const [filter, setFilter] = useState({
        title: "",
        genre: "",
        tags: [],
        company: "",
        roles: [],
        year: null
    });
    
    function checkForFilter(project)
    {
        const title = project.title.toLowerCase().includes(filter.title.toLowerCase()) || filter.title == "";
        const genre = project.genre == filter.genre || filter.genre == "";
        const tags = filter.tags.every((tag) => project.tags.includes(tag)) || filter.tags == [];
        const company = project.company.includes(filter.company) || filter.company == "";
        const roles = filter.roles.every((role) => project.roles.includes(role)) || filter.roles == [];
        const year = project.year == filter.year || filter.year == "" || filter.year == null;

        return (title && genre && tags && company && roles && year);
    }

    const coreProject = (project) => {

        const styling = {
            backgroundColor: colors.background.secondary,
            '--grid-list-hover': colors.background.quaternary
        }

        return(
            <div key={project.project} onClick={() => popup(<ProjectPage num={projects.indexOf(project)}/>)} style={styling}>
                <MediaElement {...project.mainMedia} folder="projects/"/>
                <h2>{project.title}</h2>
                <p>{project.genre}</p>
                <p>{project.company} | {project.year}</p>
            </div>
        )
    };

    const otherProject = (project) => {

        const otherProjectStyle = {
            "--project-other-display": colors.background.secondary,
            "--project-other-display-hover": colors.background.quaternary
        };

        const tooltipStyle = {
            backgroundColor: colors.background.primary
        };

        const gameplayStyle = {
            paddingTop: 2+"vh"
        }


        return(
            <div key={project.title} className="project-list-other-content">
                <div className="project-list-other-display" style={otherProjectStyle}>
                    <h2>{project.title}</h2>
                    <p>{project.genre}</p>
                    <p>{project.company} | {project.year}</p>
                </div>
                <div className="project-list-other-tooltip" style={tooltipStyle}>
                    <div className="other-tooltip-core">
                        <div className="other-tooltip-section">
                            <h4>Engine</h4>
                            <h3>{project.engine}</h3>
                        </div>
                        <div className="other-tooltip-section">
                            <h4>Tags</h4>
                            <ul>
                                {project.tags.map((tag) => {
                                    return <li key={tag}>{tag}</li>
                                })}
                            </ul>
                        </div>
                        <div className="other-tooltip-section">
                            <h4>Roles</h4>
                            <p>{project.roles.join(', ')}</p>
                        </div>
                        <div style={{display: project.notes.length == 0 ? "none" : "block"}} className="other-tooltip-section">
                            <h4>Notes:</h4>
                            {project.notes.map((note) => {
                                return <p key={note}>{note}</p>
                            })}
                        </div>
                    </div>
                    <MediaElement {...project.gameplay} width="500" height="300" styling={gameplayStyle}/>
                </div>
            </div>
        )
    };

    const displayProjects = (data) => {
        let projectList = [];
        for(let i = 0; i < data.length; i++)
        {
            if(checkForFilter(data[i])) 
            {
                projectList = [...projectList, data == projects ? coreProject(data[i]) : otherProject(data[i])];
            }
        }
        return projectList;
    };

    const [coreDisplay, setCoreDisplay] = useState(displayProjects(projects));
    const [otherDisplay, setOtherDisplay] = useState(displayProjects(otherProjects));

    const sortProjectList = (list) => {
        return list.sort((a,b) => {return a.year > b.year ? -1 : 1});
    }

    useEffect(() => {
        setCoreDisplay(displayProjects(sortProjectList(projects)));
        setOtherDisplay(displayProjects(sortProjectList(otherProjects)));
    }, [filter]);

    const filterTextStyle = (filter) => {
        return (filter == "" || filter == null) ? colors.text.secondary : colors.text.tertiary;
    };

    const inputStyle = {
        color: colors.text.primary,
        backgroundColor: colors.background.primary
    };

    const dropdownStyle = {
        color: colors.text.secondary,
        backgroundColor: colors.background.quaternary
    }

    const resetStyle = {
        color: colors.text.quaternary,
        backgroundColor: colors.background.secondary,
        "--filter-reset-hover": colors.background.quaternary
    }

    const appendList = (list, item, adding, ref) => {
        let itemList = [];
        if(adding) itemList = [...list, item];
        else 
        {
            itemList = list.filter((change) => change != item);
            ref.checked = false;
        }
        itemList.sort((a, b) => {return a > b ? 1 : -1});
        return itemList;
    };


    return (
        <div className="project-list-container" style={{backgroundColor: colors.background.tertiary}}>
            <form className="project-list-filters">
                <div>
                    <label htmlFor="title" style={{color: filterTextStyle(filter.title)}}>Search</label>
                    <input type="text" placeholder="Title" id="title" name="title" onChange={(e) => setFilter({...filter, title: e.target.value})} style={inputStyle}></input>
                </div>
                <div>
                    <label htmlFor="genre" style={{color: filterTextStyle(filter.genre)}}>Genre</label>
                    <select id="genre" name="genre" onChange={(e) => setFilter({...filter, genre: e.target.value})} style={inputStyle}>
                        <option value="">(all)</option>
                        {sortedGenres.map((genre, index) => {
                            return <option key={index} value={genre}>{genre}</option>
                        })}
                    </select>
                </div>
                <div id="tagElement">
                    <label style={{color: filterTextStyle(filter.tags)}}>Tags{filter.tags.length > 0 ? ` (${filter.tags.length})`: ""}</label>
                    <div className="filter-display-small">
                        <ul className="project-tag-display" style={inputStyle}>
                            {filter.tags.map((tag, index) => {
                                return <li key={index}>
                                            <p>{tag}</p>
                                            <button type="button" onClick={() => setFilter({...filter, tags: appendList(filter.tags, tag, false, tagRefs.current[sortedTags.indexOf(tag)])})}>x</button>
                                        </li>
                            })}
                        </ul>
                        <div className="project-tag-list" style={dropdownStyle}>
                            {sortedTags.map((tag, index) => {
                                return(
                                    <div className="project-tag-item" key={index}>
                                        <input ref={el => tagRefs.current[index] = el} type="checkbox" value={tag} id={tag} onChange={(e) => setFilter({...filter, tags: appendList(filter.tags, e.target.value, e.target.checked, tagRefs.current[sortedTags.indexOf(tag)])})}></input>
                                        <label htmlFor={tag}>{tag}</label>
                                    </div>
                            )})}
                        </div>
                    </div>
                </div>
                <div>
                    <label htmlFor="year" style={{color: filterTextStyle(filter.year)}}>Year</label>
                    <select id="year" name="year" onChange={(e) => setFilter({...filter, year: e.target.value})} style={inputStyle}>
                        <option value="">(all)</option>
                        {sortedYears.map((year, index) => {
                            return <option key={index} value={year}>{year}</option>
                        })}
                    </select>
                </div>
                <div>
                    <label htmlFor="company" style={{color: filterTextStyle(filter.company)}}>Organization</label>
                    <select id="company" name="company" onChange={(e) => setFilter({...filter, company: e.target.value})} style={inputStyle}>
                        <option value="">(all)</option>
                        {sortedCompanies.map((company, index) => {
                            return <option key={index} value={company}>{company}</option>
                        })}
                    </select>
                </div>
                <div id="roleElement">
                    <label style={{color: filterTextStyle(filter.roles)}}>Roles{filter.roles.length > 0 ? ` (${filter.roles.length})`: ""}</label>
                    <div className="filter-display-small">
                        <ul className="project-role-display" style={inputStyle}>
                            {filter.roles.map((role, index) => {
                                return <li key={index}>
                                            <p>{role}</p>
                                            <button type="button" onClick={() => setFilter({...filter, roles: appendList(filter.roles, role, false, rolesRefs.current[sortedRoles.indexOf(role)])})}>x</button>
                                        </li>
                            })}
                        </ul>
                        <div className="project-role-list" style={dropdownStyle}>
                            {sortedRoles.map((role, index) => {
                                return(
                                    <div className="project-role-item" key={index}>
                                        <input ref={el => rolesRefs.current[index] = el} type="checkbox" value={role} id={role} onChange={(e) => setFilter({...filter, roles: appendList(filter.roles, e.target.value, e.target.checked, rolesRefs.current[sortedRoles.indexOf(role)])})}></input>
                                        <label htmlFor={role}>{role}</label>
                                    </div>
                            )})}
                        </div>
                    </div>
                </div>
                <input type="reset" value="Clear" onClick={() => setFilter({title: "", genre: "", tags: [], company: "", roles: [], year: null})} style={resetStyle}></input>
            </form>
            <div className="project-list-titles">
                <h1>Full Projects</h1>
                <h1>Other Projects</h1>
            </div>
            <div className="project-list-content">
                <div className="project-list-grid">
                    {coreDisplay}
                </div>
                <div className="project-list-other">
                    {otherDisplay}
                </div>
            </div>
        </div>
    );
}
