
import { useRef, useEffect, useState } from 'react';

import {Slideshow} from './other';
import { icons, drawings, colors } from './data';
import '../styles/home.css';


export function Home(props)
{
    const myIcon = icons["jcr.png"];
    
    return (
        <div className="home-content">
            <Title icon={myIcon}/>
            <Slideshow class="home-slide" media={drawings} />
            <Content />
            <Instructions />
        </div>
    );
}

function Title(props)
{

    return(
        <div className="home-title" style={{color: colors.text.primary}}>
            <img src={props.icon}></img>
            <div className="home-title-text">
                <h1>Jonathan Rose</h1>
                <h3>Software, Web, and Game Developer</h3>
            </div>
        </div>
    );
}

function Content()
{

    return(
        <div className="home-body" style={{color: colors.text.primary}}>
            <p>Hiya! My name is Jonathan Rose, and I am a developer!</p>
            <p>Please enjoy my games and silly drawings!</p>
        </div>
    );

}


function Instructions()
{
    const [movingDown, setMovingDown] = useState(true);
    const [height, setHeight] = useState(0);
    const distance = 50;

    useEffect(() => {
        const anim = setInterval(() => {
            const speed = height > distance/2 ? 2 : 1;
            setHeight(height + (movingDown ? 1 : -1) * speed);
            if(height > distance) {setMovingDown(false)}
            if(height < 0) {setMovingDown(true)};
        }, 10);
        return () => clearInterval(anim);
    }, [height])

 
    return(
        <div className="home-instructions" style={{color: colors.text.quaternary}}>
            <h4>Scroll down!</h4>
            <h2 style={{transform: `translateY(${height}px)`}}>&#8595;</h2>
        </div>
    );
}

