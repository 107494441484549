import React, { useEffect, useState } from 'react';

import { LinkIcon } from './main';
import { icons, colors } from './data';
import '../styles/header.css';

export const labels = (sections, refs) => {

    let list = [];
    for(let i = 0; i < sections.length; i++)
    {
        const info = sections[i].props;
        list = [...list, 
            <button key={info.title} onClick={() => refs[i].current.scrollIntoView({behavior: "smooth"})}>
                <h2>{info.title}</h2>
            </button>]
    }
    return list;
}

export function Header(props)
{
    const myIcon = icons['jcr.png'];
    // DISPLAY
    const [showHead, setShowHead] = useState(true);

    let options = {
        root: document.querySelector("#page"),
        rootMargin: "0px",
        threshold: 0.8,
    };
    let callback = (entries, observer) => {
        entries.forEach(entry => {
            setShowHead(entry.isIntersecting);
    })};
    let observer = new IntersectionObserver(callback, options);

    useEffect(() => {
        observer.observe(document.getElementById('home'));
    });

    const navStyling = {
        "--nav-text-color": colors.text.secondary,
        "--nav-bg-color": colors.background.tertiary,
        "--hover-color": colors.background.quaternary
    }

    return(
        <div className="head-component" style={{display: showHead ? "none" : "flex", backgroundColor: colors.background.secondary}}>
            <img src={myIcon} onClick={() => window.location.reload(false)}></img>
            <div className="head-collapse">
                <h1 className="head-title" style={{color: colors.text.primary}}>JonRoseGames</h1>
                <HeaderIcons />
            </div>
            <div className="head-nav" style={navStyling}>
                {labels(props.sections, props.refs)}
            </div>
        </div>
    );
}

function HeaderIcons() {

    const linkedin = {
        link: "https://www.linkedin.com/in/jonathan-rose-9a5663118/",
        icon: "linkedin.png",
        alt: "My LinkedIn"
    };
    const itch = {
        link: "https://jcrose.itch.io/",
        icon: "itch.png",
        alt: "My games"
    };
    const reddit = {
        link: "https://www.reddit.com/user/fightlight55/",
        icon: "reddit.png",
        alt: "My Reddit"
    };

    const tooltip = (text) => {
        
        return (
            <div className="header-icon-tooltip" style={{backgroundColor: colors.background.quaternary, color: colors.text.secondary}}>
                <h3>{text}</h3>
            </div>
        )
    };

    return(
        <div className="header-icons">
            <LinkIcon tooltip={tooltip(linkedin.alt)} {...linkedin} />
            <LinkIcon tooltip={tooltip(itch.alt)} {...itch} />
            <LinkIcon tooltip={tooltip(reddit.alt)} {...reddit} />
        </div>
    );
}
