import React, {useState, useEffect, createElement, useRef} from 'react';

import { colors, MediaElement } from './data';
import '../styles/other.css';


export function Slideshow(props)
{
    let media = getMedia();

    const [count, setCount] = useState(0);
    const [active, setActive] = useState(media[0]);

    useEffect(() => {
        cycle(1000);
    }, [count]);

    function cycle(timeout){
        setTimeout(() => {
            setCount(() => count == media.length-1 ? 0 : count+1);
        }, timeout);
        setActive(media[count]);
    };

    function getMedia() {
        let content = [];
        for(let i = 0; i < Object.keys(props.media).length; i++)
        {
            const item = createElement("img", {key: props.media[i], src: `${Object.values(props.media)[i]}`});
            content = [...content, item];
        }
        return content;
    }

    return (
        <div className={`slideshow ${props.class}`}>
            {active}
        </div>
    );
}

export function Gallery(props)
{
    const itemsRef = useRef([]);

    const mediaList = () => {
        let media = [];
        for(let i = 0; i < props.media.length; i++)
        {
            media = [...media, {num: i, ref: itemsRef.current[i], content: props.media[i]}];
        }
        return media;
    }
    
    const displayMedia = () => {
        return(
            <div className="gallery-list">
                {media.map((item) => {
                    let styling = {
                        opacity: item.num == count || fullscreen ? 1 : .5
                    };
                    return (
                        <div key={item.num} className={`gallery-media ${props.mediaStyling}`} style={styling}>
                            <MediaElement {...item.content} mediaRef={el => itemsRef.current[item.num] = el} folder={props.folder}/>
                        </div>
                )})}
            </div>
    )};

    const numDisplay = (num) => {
        return (
            <div className="gallery-count">
                {props.media.map((item) => {
                    const selected = {scale: (props.media.indexOf(item) == num ? "2" : "1")};
                    return <p key={props.media.indexOf(item)} style={selected}>&bull;</p>
                })}
            </div>
    )};

    const [count, setCount] = useState(0);
    const [fullscreen, setFullscreen] = useState(false);
    const [media, setMedia] = useState(mediaList());
    const [list, setList] = useState(displayMedia());
    const [num, setNum] = useState(numDisplay(0));

    const [fullscreenClass, setFullscreenClass] = useState("");

    const timer = 5000;

    useEffect(() => {
        itemsRef.current[count].scrollIntoView({behavior: "smooth", inline: "center", block: "nearest"});
    }, [list]);

    useEffect(() => {
        setList(displayMedia());
        
        if(!fullscreen)
        {
            const switchTimeout = setTimeout(() => changeMedia(), timer);
            return () => clearTimeout(switchTimeout);
        }
    }, [count, fullscreen]);

    function changeMedia(forward = true)
    {
        let newCount = count + (forward ? 1 : -1);
        if(newCount < 0) { newCount = media.length-1 };
        if(newCount > media.length-1) { newCount = 0 };
        
        setNum(numDisplay(newCount));
        setCount(newCount);
    }

    function toggleFullscreen()
    {
        const toggle = !fullscreen;
        setFullscreen(toggle);
        setFullscreenClass(toggle ? "gallery-fullscreen" : "");
    }

    const hideButtons = {
        display: media.length > 1 ? "block" : "none"
    };

    return (
        <div className={`gallery ${props.class} ${fullscreenClass}`} style={props.styling}>
            <button onClick={() => {changeMedia(false)}} className="gallery-button-left" style={hideButtons}>&larr;</button>
            <div className="gallery-main">
                {list}
                {num}
            </div>
            <button onClick={() => {changeMedia(true)}} className="gallery-button-right" style={hideButtons}>&rarr;</button>
            <button className="gallery-fullscreen-button" onClick={() => toggleFullscreen()}>&#x26F6;</button>
        </div>
    );
}

export function Carousel(props)
{
    const itemsList = () => {
        let list = [];
        for(let i = 0; i < props.items.length; i++)
        {
            list = [...list, {num: i, ref: itemsRef.current[i], content: props.items[i]}];
        }
        return list;
    }

    const itemsRef = useRef([]);
    const carouselRef = useRef(null);

    const spacer = 200;

    const [startNum, setStartNum] = useState(0);
    const [maxShown, setMaxShown] = useState(0);
    const [itemElements, setItemElements] = useState(() => {
        const items = itemsList();
        let elements = [];
        for(let i = 0; i < items.length; i++)
        {
            elements = [...elements, 
                    <div className="carousel-item" ref={el => itemsRef.current[items[i].num] = el} key={items[i].num}>
                        {items[i].content}
                    </div>
                ];
        }
        return elements;
    });
    const [itemDisplays, setItemDisplays] = useState(<></>);

    const [indicators, setIndicators] = useState(<></>);
    
    const handleResize = () => {
        setMaxShown(Math.max(Math.floor(carouselRef.current.offsetWidth / spacer), 1));
    };

    //Init
    useEffect(() => {
        handleResize();
    }, []);

    //Window resize
    useEffect(() => {
        
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    //Rendering
    useEffect(() => {

        setItemDisplays(() => {
            const shown = () => {
                let items = [];
                for(let i = 0; i < maxShown; i++)
                {
                    items = [...items, itemElements[i+startNum]];
                }
                return items;
            }

            return (
                <div className="carousel-shown">
                    {shown()}
                </div>
        )});

        setIndicators(() => {
            return (
                <div className="carousel-indicators">
                    {itemsList().map((item, index) => {
                        const inRange = item.num >= startNum && item.num < startNum+maxShown;
                        const styling = {
                            color: inRange ? colors.text.tertiary : colors.text.secondary
                        };
                        return <p key={index} style={styling}>&bull;</p>
                    })}
                </div>
            )
        })

        // setItemDisplays(() => {

        //     if(itemsRef.current[startNum] != null) 
        //     {
        //         itemsRef.current[startNum].scrollIntoView({behavior: "smooth", block: "nearest", inline: "center"});
        //     }

        //     return (
        //         <>
        //             {itemsList().map((item) => {
        //                 let itemClass = "";
        //                 if(item.num == startNum) itemClass += "carousel-item-start ";
        //                 if(item.num == startNum+maxShown-1) itemClass += "carousel-item-end ";
        //                 return(
        //                     <div className={itemClass} style={marginStyle} key={item.num}>
        //                         {itemElements[item.num]}
        //                     </div>
        //             )})}
        //         </>
        //     )
        // });

    }, [startNum, maxShown]);

    function scroll(forward)
    {
        let num = startNum + ((forward ? 1 : -1)*maxShown);
        if(num < 0) num = 0;
        if(num > props.items.length-maxShown) num = props.items.length-maxShown;
        setStartNum(num);
    }

    return (
        <div className={`carousel ${props.class}`}>
            <button onClick={() => scroll(false)} disabled={startNum==0}>&larr;</button>
            <div className="carousel-list-element" style={props.styling} ref={carouselRef}>
                {itemDisplays}
                {indicators}
            </div>
            <button onClick={() => scroll(true)} disabled={startNum==props.items.length-maxShown}>&rarr;</button>
        </div>
    );

}

export function DisplayBox(props) {

    const [active, setActive] = useState(0);
    const [shown, setShown] = useState(props.content[active]);

    useEffect(() => {
        setShown(props.content[active]);
    }, [active]);

    return (
        <div className={`display-box ${props.boxClass}`}>
            <select onChange={(e) => {setActive(e.target.value)}} style={{color: colors.text.primary}}>
                {props.content.map((item) => {
                    return <option value={item.num} key={item.num}>{item.title}</option>
                })}
            </select>
            <div className={`display-content ${props.contentClass}`}>
                {shown.content}
            </div>
        </div>
    );
}
