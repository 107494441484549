import React from "react";

import { icons, colors, images } from "./data";
import '../styles/core.css';

// Functions

export function importAll(r)
{
    let list = {};
    r.keys().map((item, index) => { list[item.replace('./', '')] = r(item); });
    return list;
}

export function getUniqueValues(toParse, flatNum = 1)
{
    let list = [];
    let values = toParse.flat(flatNum);
    list = [...new Set(values)];
    return list;
}


// Elements

export function SectionHead(props) {

    return (
        <>
            <h2 className="section-header" style={{color: colors.text.primary}}>{props.title}</h2>
        </>
    )
}

export function Footer() {


    return(
        <div className="footer" style={{backgroundColor: colors.background.secondary, color: colors.text.secondary}}>
            <p>Copyright &copy; 2024 Jonathan Rose</p>
        </div>
    );
}

export function LinkIcon(props) {

    return(
        <div className="link-icon">
            <a href={props.link} target="_blank" rel="noreferrer">
                <img src={icons[props.icon]} alt={props.alt}></img>
            </a>
            {props.tooltip}
        </div>
    );
}

