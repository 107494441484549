import React, { createContext, useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import {useInView, InView} from 'react-intersection-observer';


import { Home } from './scripts/home';
import {Projects} from './scripts/projects';
import {About} from './scripts/about'
import { Header } from './scripts/header';
import { Footer } from './scripts/main';
import { colors } from './scripts/data';

import "./styles/core.css";
import "./styles/fonts.css";


const root = ReactDOM.createRoot(document.getElementById('root'));

export const PopupContext = createContext();

root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);


function App()
{

  // PAGE
  const sections = [
    <Home id="home" title="Home"/>,
    <Projects id="projects" title="Projects"/>,
    <About id="about" title="About Me"/>
  ];
  const refList = [useRef(null), useRef(null), useRef(null)];
  
  const pageSections = () => {
    let sectionList = [];
    for(let i = 0; i < sections.length; i++)
    {
      sectionList = [...sectionList, 
              <div id={sections[i].props.id} className="content-container" ref={refList[i]} key={i}>
                  {sections[i]}
              </div>]
    }
    return sectionList;
  }


  // POPUP
  const [popupInfo, setPopupInfo] = useState(null);
  const [popupDisplay, setPopupDisplay] = useState("none");

  useEffect(() => {
    setPopupDisplay(popupInfo == null ? "none" : "block");
  }, [popupInfo]);

  const closeButtonStyle = {
    color: colors.text.secondary, 
    backgroundColor: colors.background.primary,
    "--close-button-hover": colors.background.quaternary
  }

  //RENDER
    return(
      <div id="page" style={{backgroundColor: colors.background.primary}}>
        <PopupContext.Provider value={setPopupInfo}>
            <Header sections={sections} refs={refList}/>
            <div className="body-content">
              {pageSections()}
            </div>
            <div className="popup" style={{display: popupDisplay, backgroundColor: colors.background.tertiary}}>
              <button id="closeButton" onClick={() => {setPopupInfo(null)}} style={closeButtonStyle}>Return</button>
              {popupInfo}
            </div>
            <Footer />
        </PopupContext.Provider>
      </div>
    );
}

